import React, { useState } from 'react';
import './SignInModal.css';

// Définition des types pour les props
interface SignInModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const SignInModal: React.FC<SignInModalProps> = ({ isOpen, onClose }) => {
    const [signInType, setSignInType] = useState<'EMAIL' | 'PHONE'>('EMAIL');
    const [email, setEmail] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>(''); // Type pour le numéro de téléphone
    const [isPersistent, setIsPersistent] = useState<boolean>(false);
    const [isChecked, setIsChecked] = useState<boolean>(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleSignInTypeChange = (type: 'EMAIL' | 'PHONE') => {
        setSignInType(type);
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(event.target.value); // Mettre à jour le numéro de téléphone
    };

    const handlePersistentChange = () => {
        setIsPersistent(!isPersistent);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.log(`Sign in as: ${signInType}, Email: ${email}, Stay signed in: ${isPersistent}`);
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                    <div data-testid="logo" className="TopLine_logo_wrapper" role="group" tabIndex={0}>
                        <a className="TopLine_logo" href="#">
                            <img src="/assets/SVG/logo.SVG" alt="Freshdirect logo" className="TopLine_logo_image" />
                        </a>
                    </div>
                    <div className="close-icon" role="button" tabIndex={0} onClick={onClose}>

                        <img src="/assets/SVG/close.png" alt="Close icon" className="close-icon-image" />

                    </div>
                </div>

                <section className="AuthV2_container">
                    <h2 id="auth_modal_title">Sign in to your account</h2>
                    <div className="Toggle_container">
                        <div className="Toggle_option">
                            <label
                                htmlFor="sign-up-type_PHONE"
                                className={`Toggle_label ${signInType === 'PHONE' ? 'active' : ''}`}
                                onClick={() => handleSignInTypeChange('PHONE')}
                            >
                                <span className="Toggle_icon">
                                    <img src="/assets/SVG/phone.png" alt="Phone" width={16} height={16} />
                                </span>
                                Phone Number
                            </label>
                        </div>
                        <div className="Toggle_option">
                            <label
                                htmlFor="sign-up-type_EMAIL"
                                className={`Toggle_label ${signInType === 'EMAIL' ? 'active' : ''}`}
                                onClick={() => handleSignInTypeChange('EMAIL')}
                            >
                                <span className="Toggle_icon">
                                    <img src="/assets/SVG/gmail.png" alt="Email" width={16} height={16} />
                                </span>
                                Email
                            </label>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit}>
                        {signInType === 'EMAIL' && (
                            <div className="SignInEmail_field">
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={email}
                                    onChange={handleEmailChange}
                                    placeholder=" " /* Ajoutez un placeholder vide pour l'effet flottant */
                                    required
                                />
                                <label htmlFor="email" className={signInType === 'EMAIL' ? 'active' : ''}>
                                    Email
                                </label>
                            </div>
                        )}
                        {signInType === 'PHONE' && (
                            <div className="SignInPhone_field">
                                <input
                                    type="tel"
                                    id="phone"
                                    name="phone"
                                    value={phoneNumber}
                                    onChange={handlePhoneChange}
                                    placeholder=" " /* Ajoutez un placeholder vide pour l'effet flottant */
                                    required
                                />
                                <label htmlFor="phone" className={signInType === 'PHONE' ? 'active' : ''}>
                                    Phone Number
                                </label>
                            </div>
                        )}
                        <div className="SignInEmail_submit_button_wrapper">
                            <button type="submit" disabled={!email && !phoneNumber}>Continue</button>
                        </div>
                        <div className="signIn_field">
                            <input
                                id="persistent-login"
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                                className="checkbox"
                            />
                            <label htmlFor="persistent-login" className="checkbox-label">
                                <span className="mr-1"></span>
                                Stay signed in
                            </label>
                        </div>
                    </form>

                    <div className="SocialButtons_socials_modal">
                        <div className="custom-divider" role="separator">
                            <span>or</span>
                        </div>
                        <div>
                            <iframe
                                title="Login with Social Networks"
                                src="https://freshdirect.api.oneall.com/socialize/login/frame/?oakk=25656&oakv=0655ee9235e519cdf7de6cbf1b20ab1fb10e5e240f87805fe927cdfa03de7961abddb9fdd240c58391ba3191da35e7ef35cd022b1584cbbf4562939559b254fa1a8cfe45c189164da58cd7ab67377f6662c40588dce40708d505c5af6084e78284716bb0816913d2b44f429f2cde0c46c6d26d9451baa70dddc25f096ccabc302e72ccd790bb680fe3e36f8570737e78897f06ad8d7a4220d85877cac80be8ecba6cc3f06e19f141188c761647df653b046d892bffc6602783a33d996fc963122be71bc41d64ec87e19d27d177ed05d80f6812007b8576c8a323c35be70ea23d1bb9ca11a31a3dadab9f2895907c7175e9d3228b1005249b1bca5721ff54f41d21a8aba21d2ed17aefeedc345ad5f25d92b4613e4a1bc31fcc66b3e40cca997aa7a4321433e7a79dabd5da93071c8ddb6cd0740a43a7661d585e5001943fea9b46a6d6b69655eba92aad29d14290c0c4292a215d19800f86e2679cc012cbb51de3c5fff76a762d9ca953fcd355bab00b966e3d868102db6ec97ce632a7907fe4a219d889ceeba133655bdee56fef750c500aad1b433d3025fe886c1710399b90e30b5a9a81b24560c0cf57ee3df52c7baa491a2cfe117f6a9ba4116ef078414e4b42aef90afb162c641d9815bdd0d2cb1cdbd9dc0bb0defd16846accd7a699d8929bc678aaa2207247e38c4984fec7d516afeb81c3b21bd1339aad36aaf80992&lang=en"
                                style={{ border: "0", width: "100%", height: "140px", backgroundColor: "transparent" }}
                            />
                        </div>
                    </div>
                    <div className='create-account' >
                        Don't have an account?
                        <button  className='create-account-button'onClick={() => { /* Logic to switch to sign-up */ }}>Create an account</button>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default SignInModal;
