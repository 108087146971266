import AppetizersData from "./CategorieData/AppetizersData";
import CarrotsData from "./CategorieData/CarrotsData";
import deliSaladsData from "./CategorieData/DeliSaladsData";
import MinuteMealsData from "./CategorieData/MinuteMealsData";


export const CategoryDataMap = {
  "deli-salads": deliSaladsData,
  '4-minute-meals': MinuteMealsData,
  'appetizers':AppetizersData,
  'carrots':CarrotsData,
  // Ajouter d'autres catégories ici
  // "autre-catégorie": autreCatégorieData,
};
