import { MenuData } from "./MenuData";

export const FrozenData:MenuData[] = [
    {
     
      label: 'Popular Frozen',
      image: '/assets/menu/cat.avif',
      categories: [
        'Ice Cream & Treats',
        'Fruit',
        'Meals & Entrées',
        'Vegetables',
        'Meat',
        'Breakfast Food',
        'Pizza',
        'Fries & Potatoes',
        
      ],
    },
    {
        
        label:  'More Frozen',
        image: '/assets/menu/cat.avif',
        categories: [
            'Appetizers & Snacks',
            'Bread & Dough',
            'Desserts & Toppings',
            'Baby & Kids',
            'Meat Alternatives',
            'Pasta',
            'Seafood',
            'Smoothies',
            'Soup & Broth',
            'Ice',
            'See all Popular Frozen',
            'See all More Frozen',
          
        ],
      },
  ];
  
  
       