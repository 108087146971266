import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import { useState } from 'react';
import React from 'react';

import Footer from './Components/HomePage/Footer/Footer';
import Navbar from './Components/HomePage/Header/Navbar/Navbar';
import Navbar2 from './Components/HomePage/Header/Navbar2/Navbar2';
import TopLine from './Components/HomePage/Header/TopLine/TopLine';
import GiveawayComponent from './Components/HomePage/MainContent/Giveaway/Giveaway';
import HomePage from './Pages/HomePage';
import './Styles/Global.css';
import CategoryPageWrapper from './Components/Categories/CategoryPageWrapper';

import DepartmentPage from './Pages/DepartmentPage';
import DepartmentsPage2 from './Pages/DepartmentsPage2';
import { CartProvider } from './Components/HomePage/CartContext';
import Carousel from './Components/HomePage/Carousel/Carousel';
import AllProductsPage from './Components/HomePage/MainContent/ShopBy/AllProducts';


const App: React.FC = () => {
  const [location, setLocation] = useState('Home');
  const [activeNavItem, setActiveNavItem] = useState<string>(''); // Valeur par défaut ou initialisation

  const handleLocationChange = (newLocation: string) => {
    setLocation(newLocation);
  };

  return (
    <CartProvider>
      <Router>

        <>
          <TopLine location={location} onLocationChange={handleLocationChange} />
          {location === 'Home' ? <Navbar /> : <Navbar2 />}
          <Carousel/>
          <GiveawayComponent />
          <Routes>
            <Route path="/" element={<HomePage />} />
            {/* Route pour la page de catégorie avec un paramètre dynamique */}
            <Route path="/category/:category" element={<CategoryPageWrapper />} />
            {/* Route pour DepartmentsSection */}
            <Route path="/:departmentName" element={<DepartmentPage />} />
            <Route path="/:departmentName/:label" element={<DepartmentsPage2 />} />
            <Route path="/all-products" element={<AllProductsPage />} />
          </Routes>
          <Footer />
        </>
      </Router>
    </CartProvider>

  );
};

export default App;
