import React, { useState, useEffect } from 'react';
import './ShopBy.css';
import { ShopByData } from './ShopByData';
import { CategoryDataMap } from '../../../Categories/categoryDataMap';
import ShopByMenu from './ShopByMenu';
import ProductList from './ProductList';
import { Product } from '../../../Categories/Product';
import PopularProducts from './PopularProduct';

interface ShopByItem {
  id: number;
  label: string;
  color: string;
}

const ShopByComponent: React.FC = () => {
  const [items] = useState<ShopByItem[]>(ShopByData);
  const [activeItemId, setActiveItemId] = useState<number | null>(1);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [allProducts, setAllProducts] = useState<Product[]>([]);

  useEffect(() => {
    const all = Object.values(CategoryDataMap).flat() as Product[];
    setAllProducts(all);

    // Filtrer les produits par défaut (par exemple, EXPESS)
    const defaultFiltered = all.filter(product => product.shopBy.includes("EXPRESS"));
    setFilteredProducts(defaultFiltered);
  }, []);

  useEffect(() => {
    if (activeItemId !== null) {
      const selectedShopBy = items.find(item => item.id === activeItemId);

      if (selectedShopBy) {
        const filtered = allProducts.filter(product =>
          product.shopBy.includes(selectedShopBy.label)
        );
        setFilteredProducts(filtered);
      } else {
        setFilteredProducts([]);
      }
    }
  }, [activeItemId, items, allProducts]);

  const handleClick = (id: number) => {
    setActiveItemId(id === activeItemId ? null : id);
  };

  return (
    <div className="shop-by-interface">
      <div className="shop-by-menu-container">
        <ShopByMenu items={items} activeItemId={activeItemId} onClick={handleClick} />
      </div>
      <ProductList products={filteredProducts} />
      <div>
        <PopularProducts products={allProducts} />
      </div>
    </div>
  );
};

export default ShopByComponent;
