import React from 'react';
import { Product } from '../../../Categories/Product';
import AppetizersData from '../../../Categories/CategorieData/AppetizersData';
import CarrotsData from '../../../Categories/CategorieData/CarrotsData';
import deliSaladsData from '../../../Categories/CategorieData/DeliSaladsData';
import MinuteMealsData from '../../../Categories/CategorieData/MinuteMealsData';
import QuantitySelector from '../../../Categories/QuantitySelector/QuantitySelector';
import { useCart } from '../../CartContext';
import './AllProduct.css';

const allProducts = [
    ...AppetizersData,
    ...CarrotsData,
    ...deliSaladsData,
    ...MinuteMealsData,
];

const AllProductsPage = () => {
    const popularProducts = allProducts.filter((product) => product.isPopular);
    const { addToCart } = useCart();

    const handleAddToCart = (product: Product, quantity: number) => {
        const cartItem = {
            id: product.id.toString(),
            name: product.name,
            quantity,
            price: parseFloat(product.price),
            media: product.media,
            brand: product.brand,
            unitSize: product.unitSize,
            unitPrice: product.unitPrice,
            shopBy: product.shopBy,
        };
        addToCart(cartItem, quantity);
    };

    return (
        <div className="all-products-page">
            <div className="all-products-image-text-container">
                <div className="all-products-image-column">
                    <img src="assets/menu/2 1.PNG" alt="Popular Products" className="all-products-image" />
                </div>
                <div className="all-products-text-column">
                    <h2>Popular Products</h2>
                    <p>Discover all our popular products below!</p>
                </div>
            </div>
            <div className="all-products-list">
                {popularProducts.map((product) => (
                    <div className="all-products-item" key={product.id}>
                        <div className="all-products-item-image">
                            {product.media.toLowerCase().endsWith('.mp4') ? (
                                <video src={product.media} className="all-products-media" />
                            ) : (
                                <img src={product.media} alt={product.name} className="all-products-media" />
                            )}
                        </div>
                        <div className="all-products-item-info">
                            <p className="all-products-brand">{product.brand}</p>
                            <p className="all-products-name">{product.name}</p>
                            <p className="all-products-price">{product.price}</p>
                            <QuantitySelector
                                initialQuantity={0}
                                onQuantityChange={(quantity) => handleAddToCart(product, quantity)}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AllProductsPage;
