import { MenuData } from "./MenuData";

export const GroceryData: MenuData[] = [
    {
        label: 'Beverages',
        image: '/assets/menu/cat.avif',
        categories: [
            'Cocktail Mixers',
            'Coconut & Aloe Water',
            'Cold Brew & Bottled Coffee',
            'Energy',
            'Iced Tea',
            'Juice & Fruit Drinks',
            'Kombucha',
            'Milk, Plant-Based',
            'Milk, Shelf-Stable',
            'Non-Alcoholic Wine & Cocktails',
            'Probiotic & Functional',
            'Soda & Pop',
            'Sports & Nutritional',
            'Water & Seltzer',
            'See all Beverages',
        ],
    },
    {
        label: 'Snacks',
        image: '/assets/menu/cat.avif',
        categories: [
            'Applesauce & Squeezes',
            'Bars',
            'Candy & Chocolate',
            'Chips',
            'Cookies, Cakes & Pastry',
            'Crackers',
            'Fruit Snacks & Leathers',
            'Jerky',
            'Nuts, Seeds & Dried Fruit',
            'Popcorn',
            'Pretzels',
            'Rice Cakes',
            'Salsa & Dips',
            'Seaweed & Kale',
            'See all Snacks',
        ],
    },
    {
        label: 'Popular Pantry',
        image: '/assets/menu/cat.avif',
        categories: [
            'Bread',
            'Breakfast & Cereal',
            'Coffee, Tea & Cocoa',
            'Canned Goods',
            'Macaroni & Cheese',
            'Pasta & Sauce',
            'Rice, Beans & Grains',
            'Soup, Broth & Ramen',
            'Oil & Vinegar',
            'Baking Needs',
            'Condiments & Sauces',
            'Herbs & Spices',
            'Easy Sides',
            'Nut Butters & Fruit Spreads',
            'Salad Dressing',
            'Sugar & Sweeteners',
            'See all Popular Pantry',
            'See all Other Pantry',
        ],
    },
    {
        label: 'Beer & Cider',
        image: '/assets/menu/cat.avif',
        categories: [
            'Beer, Craft',
            'Beer, Domestic',
            'Beer, Imported',
            'Beer, Non-Alcoholic',
            'Cider & Fruit',
            'Hard Seltzer & More',
            'See all Beer & Cider',
        ],
    },
    {
        label: 'Household',
        image: '/assets/menu/cat.avif',
        categories: [
            'Bags & Wraps',
            'Cleaners',
            'Dishwashing',
            'Facial Tissue',
            'Gifts & Kitchen Tools',
            'Laundry',
            'Paper & Plasticware',
            'Paper Towels',
            'Supplies',
            'Toilet Paper & Bathroom Tissue',
            'Trash Bags',
            'See all Household',
        ],
    },
    {
        label: 'Health & Beauty',
        image: '/assets/menu/cat.avif',
        categories: [
            'Bath & Body',
            'Dental Care',
            'Deodorant',
            'Diet & Nutrition',
            'Drugstore',
            'Hair Care',
            'Shaving',
            'Skin Care',
            'Vitamins & Supplements',
            'See all Health & Beauty',
        ],
    },
    {
        label: 'Pets',
        image: '/assets/menu/cat.avif',
        categories: [
            'Cat Food',
            'Cat Treats & Toys',
            'Cat Care',
            'See all Cats',
            'Dog Food',
            'Dog Treats & Toys',
            'Dog Care',
            'See all Dogs',
        ]
    },

];



