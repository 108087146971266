import { MenuData } from "./MenuData";

export const BeerCiderData: MenuData[] = [
  {
    label: 'Beer & Cider',
    image: '/assets/menu/cat.avif',
    categories: [
      'Beer, Craft',
      'Beer, Domestic',
      'Beer, Imported',
      'Beer, Non-Alcoholic',
      'Cider & Fruit',
      'Hard Seltzer & More',
    ],
  },
];
