import { MenuData } from "./MenuData";

export const PantryData: MenuData[] = [
  {
    label: 'Pantry',
    image: '/assets/menu/cat.avif',
    categories: [
      'Baking Needs',
      'Canned Goods',
      'Condiments',
      'Jelly & Honey',
      'Nut Butters',
      'Pasta',
      'Rice & Beans',
      'Spices & Herbs',
      'Sugar',
    ],
  },
];
