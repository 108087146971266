import { MenuData } from "./MenuData";




export const PreparedData: MenuData[] = [
  {
    label: 'Prepared Foods',
    image: '/assets/menu/cat.avif',
    categories: [
      {
        label: '4-Minute Meals',
        subcategories: [
          'Beef, Pork, & Lamb',
          'Chicken & Poultry',
          'Eggs',
          'Oatmeal',
          'Pasta',
          'Seafood',
          'Vegetable',
        ]
      },
      {
        label: 'Appetizers',
        subcategories: [
        '  Dumplings & Buns',
         ' Ready-to-Bake',
          'Ready-to-Eat',
          'Wings & Chicken Fingers'
        ]
      },
      'Breakfast',
     
      {
        label: 'Deli Salads',
        subcategories: [
          'Cases',
          'Freshly Cut',
          'Whole',
        ]
      },
      'Entrées',
      'Green Salads',
      'Lunches & Snack Packs',
      'Marinated & Kabobs',
      'Pizza',
      'Quiche & Pot Pie',
      'Rotisserie',
      'Salsas & Dips',
      'Sandwiches & Wraps',
      'Sauces & Marinades',
      'Sides',
      'Soup & Chili',
      'Sushi',

     
     
    ],
  },
  {
    label: 'Deli',
    image: '/assets/menu/cat.avif',
    categories: [
      'Sliced Cheese',
      'Sliced Deli Meat',
      'Charcuterie',
     
      'Custom Deli Bundles',
      'Packaged Deli Meat',
      'Packaged Cheese',
      'Smoked & Cured Fish',
      'Snack Packs',
      'Bacon & Pancetta',
      'Antipasti',
      'Olives',
      'Pâté & Caviar',
      'Pickles & Sauerkraut',
      'Non-Dairy Cheese',
      'Meatless Deli Slices & Bacon',
    ],
  },
  {
    label: 'Cheese Shop',
    image: '/assets/menu/cat.avif',
    categories: [
      'Blue',
      'Brie & Camembert',
      'Cheddar & Jack',
      'Cheese Boards',
      'Feta & Goat',
      'Gouda',
      'Gruyère & Alpine',
      'Mozzarella & Burrata',
      'Manchego & Sheep',
      'Other',
      'Parmesan & Grating',
    ],
  },
  {
    label: 'Catering',
    image: '/assets/menu/cat.avif',
    categories: [
      'Antipasti',
      'Appetizers - Heat & Serve',
      'Breakfast & Brunch',
      'Caviar',
      'Cheese Boards',
      'Crudité',
      
      'Dips',
      'Entrées',
      'Green Salads',
      'Lunches & Snack Packs',
      'Packages',
      'Sandwiches',
      'Shrimp',
      'Sweets',
    ],
  },
];
