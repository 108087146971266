// NavData.ts
export interface NavItem {
    icon: string;
    label: string;
}

export const NavData2: NavItem[] = [
    {
        icon: "/assets/SVG/icon3.svg",
        label: "Fresh",

    },
    {
        icon: "/assets/SVG/icon5.svg",
        label: "Dairy",

    },
    {
        icon: "/assets/SVG/icon13.svg",
        label: "Snacks",

    },
    {
        icon: "/assets/SVG/icon14.svg",
        label: "Meals",

    },
    {
        icon: "/assets/SVG/icon2.svg",
        label: "Catering Platters",

    },
    {
        icon: "/assets/SVG/icon15.svg",
        label: "Beverages",

    },
    {
        icon: "/assets/SVG/icon16.svg",
        label: "Beer & Cider",

    },
    {
        icon: "/assets/SVG/icon9.svg",
        label: "Wine & Spirits",

    },
    {
        icon: "/assets/SVG/icon19.svg",
        label: "Pantry",

    },
    {
        icon: "/assets/SVG/icon20.svg",
        label: "Supplies",

    }
];
