import React, { useState, useEffect } from 'react';
import { NavData1 } from './NavData';
import './Navbar.css';
import MenuIcon from './MenuIcon';
import Dropdown from '../../../Dropdown/Dropdown';
import { menuDataMapping } from '../../../Dropdown/DropdownData/menuDataMapping';
import { Link, useNavigate } from 'react-router-dom';
import CreateAccount from '../Account/CreateAccount/CreateAccount';
import SignInModal from '../Account/SignInModal/SignInModal';
import ModalWindow from '../Delivery/Delivery';
import { Logo, SearchBar, CartButton, DeliverySection } from '../TopLine/TopLine';
import { useCart } from '../../CartContext';
import { itemsToSearch } from '../TopLine/itemsToSearch';
import CartModal from '../../Cart';

interface NavItem {
  label: string;
  icon: string;
}

const Navbar: React.FC = () => {
  const [navbar] = useState<NavItem[]>(NavData1);
  const [activeDropdown, setActiveDropdown] = useState<number | null>(null);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 992);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState<boolean>(false);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState<boolean>(false);
  const [isCreateAccountModalOpen, setIsCreateAccountModalOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const [isCartModalOpen, setIsCartModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { cartItems } = useCart(); // Récupère les articles du panier depuis le contexte

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMenuToggle = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const handleMouseEnter = (id: number) => {
    if (!isMobile) {
      setActiveDropdown(id);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setActiveDropdown(null);
    }
  };

  const handleDropdownClick = (e: React.MouseEvent, id: number, label: string) => {
    e.preventDefault();
    const labelSlug = label.toLowerCase().replace(/ & /g, '-').replace(/ /g, '-');
    if (isMobile) {
      setIsMenuOpen(false);
    }
    navigate(`/${labelSlug}`);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    if (value) {
      const results = itemsToSearch.filter(item =>
        item.toLowerCase().includes(value.toLowerCase())
      );
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  const clearSearch = () => {
    setSearchValue('');
    setSearchResults([]);
  };

  const handleSearchFocus = () => setIsFocused(true);
  const handleSearchBlur = () => {
    if (!searchValue) setIsFocused(false);
  };

  const openAddressModal = () => setIsAddressModalOpen(true);
  const closeAddressModal = () => setIsAddressModalOpen(false);

  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg navbar-white" style={{ backgroundColor: '#ffffff' }}>
        <div className="container-fluid d-flex flex-column">
          {/* Niveau 1 : Menu (Logo, Menu, Panier) */}
          <div className="navbar-top d-flex align-items-center justify-content-between w-100">
            {/* Affichage du Menu Icon et Panier uniquement sur mobile */}
            {isMobile && <MenuIcon isMenuOpen={isMenuOpen} onToggle={handleMenuToggle} />}
            {isMobile && <Logo />}
            {isMobile && (
              <div className="cart-button-container">
              <CartButton
                cartItemCount={cartItems.length}
                onClick={() => setIsCartModalOpen(true)}
              />
            </div>
            )}
          </div>

          {/* Niveau 2 : Barre de recherche */}
          {isMobile && (
            <div className="search-bar-container w-100">
              <SearchBar
                searchValue={searchValue}
                onChange={handleSearchChange}
                onFocus={handleSearchFocus}
                onBlur={handleSearchBlur}
                clearSearch={clearSearch}
                isFocused={isFocused}
              />
            </div>
          )}

          {/* Menu de navigation pour mobile */}
          <div className={`navbar-menu ${isMenuOpen ? 'show' : 'hide'}`} id="navbarNav">
            {isMenuOpen && isMobile && (
              <>
                <button className="close-btn" onClick={handleMenuToggle}>
                  &times;
                </button>
                <div className="delivery-section">
                  <img
                    src="/favicon.ico"
                    alt="Go to homepage"
                    width="23"
                    height="23"
                    className="delivery-icon"
                  />
                  <DeliverySection onOpenAddressModal={openAddressModal} />
                </div>

                {/* Boutons Sign In et Create Account */}
                <div className="account-popup-menu">
                  <button className="account-link" onClick={() => setIsSignInModalOpen(true)}>
                    Sign In
                  </button>
                  <span className="or-text">or</span>
                  <button className="account-link" onClick={() => setIsCreateAccountModalOpen(true)}>
                    Create an Account
                  </button>
                </div>
              </>
            )}

            {/* Liste des éléments du menu (toujours visible sur grand écran) */}
            <ul className="navbar-nav ms-auto">
              {navbar.map((item, index) => (
                <li
                  className="nav-item"
                  key={index}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  <Link
                    to="#"
                    className="nav-link text-dark d-flex flex-column align-items-center"
                    onClick={(e) => handleDropdownClick(e, index, item.label)}
                    aria-haspopup="true"
                    aria-expanded={activeDropdown === index ? 'true' : 'false'}
                  >
                    <div className="icon-container">
                      <img src={item.icon} alt={item.label} className="nav-icon" />
                    </div>
                    <div className="label-button-container">
                      <span className="nav-label">{item.label}</span>
                      <button
                        className={`GlobalNav_nav_item_caret ${activeDropdown === index ? 'open' : ''}`}
                        type="button"
                        aria-haspopup="true"
                        aria-expanded={activeDropdown === index ? 'true' : 'false'}
                      >
                        <img
                          src="/assets/SVG/chevron.png"
                          alt="Chevron"
                          className={`chevron-icon ${activeDropdown === index ? 'open' : ''}`}
                        />
                      </button>
                    </div>
                  </Link>

                  {/* Dropdown uniquement sur les écrans de bureau */}
                  {!isMobile && activeDropdown === index && (
                    <div>
                      <Dropdown
                        label={item.label}
                        menuData={menuDataMapping[item.label as keyof typeof menuDataMapping] || { label: item.label, categories: [] }}
                        departmentName={item.label}
                      />
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>

      {/* Modals */}
      <SignInModal
        isOpen={isSignInModalOpen}
        onClose={() => setIsSignInModalOpen(false)}
      />
      <CreateAccount
        isOpen={isCreateAccountModalOpen}
        onClose={() => setIsCreateAccountModalOpen(false)}
      />
      <CartModal isOpen={isCartModalOpen} onClose={() => setIsCartModalOpen(false)} />
      {isAddressModalOpen && <ModalWindow onClose={closeAddressModal} />}

    </header>
  );
};

export default Navbar;
