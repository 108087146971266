// data/bakeryData.ts

import { MenuData } from "./MenuData";



export const BakeryData: MenuData[] = [
    {
        label: 'Bread',
        image: '/assets/menu/cat.avif',
        categories: [
            'Artisanal Bread & Rolls',
            'Bagel Shop',
            'Breakfast',
            'Packaged Bread & Rolls',
        ],
    },
    {
        label: 'Sweets',
        image: '/assets/menu/cat.avif',
        categories: [
            'Baking Kits',
            'Cakes & Cupcakes',
            'Cookies & Bars',
            'Pies & Tarts',
            'Pudding & Soufflé',
            'Seasonal & Specialty',
        ],
    },
    
];
