import React, { useState } from 'react';
import { useCart } from './CartContext';
import './Cart.css';
import QuantitySelector from '../Categories/QuantitySelector/QuantitySelector';
import { FaTrash } from 'react-icons/fa';
import Product from '../Categories/Product';

interface CartProps {
  onClose: () => void;
  isOpen: boolean;
}

const parsePrice = (priceString: string): number => {
  const match = priceString.match(/[\d.]+/);
  return match ? parseFloat(match[0]) : 0;
};

// CartModal.tsx
// CartModal.tsx
const CartModal: React.FC<CartProps> = ({ onClose, isOpen }) => {
  const { cartItems, updateQuantity, removeFromCart } = useCart();
  const [notification, setNotification] = useState<string | null>(null);
  const [quantities, setQuantities] = useState<{ [key: string]: number }>({}); // Utilisation du `name` comme clé


  const handleRemoveFromCart = (productName: string) => {
    // Supprimer l'élément du panier
    removeFromCart(productName);

    // Réinitialiser la quantité à 0
    setQuantities((prev) => ({ ...prev, [productName]: 0 }));

    // Vous pouvez également afficher une notification pour informer l'utilisateur
    setNotification(`${productName} a été supprimé du panier.`);
    setTimeout(() => setNotification(null), 3000);
  };
  return (
    <div className={`modal-overlay ${isOpen ? 'active' : ''}`}>
      <div className={`cart-sidebar ${isOpen ? 'slide-in' : 'slide-out'}`}>
        <div className="cart-header">
          <h2>Panier ({cartItems.length})</h2>
          <button className="close-button" onClick={onClose}>×</button>
        </div>

        <div className="cart-body">
          {cartItems.length === 0 ? (
            <div className="text-center empty-cart">
              <h1>Your bag is empty</h1>
              <span>Let’s find something for you to enjoy!</span>
            </div>
          ) : (
            <div className="product-list">
              {cartItems.map((item) => {
                const price = parsePrice(item.unitPrice);

                return (
                  <div key={item.name} className="cart-product-item">
                    <div className="cart-item">
                      {item.media && item.media.toLowerCase().endsWith('.mp4') ? (
                        <video src={item.media} className="cart-product-image" />
                      ) : (
                        <img src={item.media || '/default-image.png'} alt={item.name} className="cart-product-image" />
                      )}
                    </div>
                    <div className="cart-product-details">
                      <div className="cart-shop-by-labels">
                        {item.shopBy.map((shopBy, i) => (
                          <span key={shopBy} style={{ fontSize: 14 }}>
                            {shopBy}
                            {i !== item.shopBy.length - 1 && <span className="cart-vertical-divider"> | </span>}
                          </span>
                        ))}
                      </div>

                      <div>
                        <p className="cart-product-brand">{item.brand}</p>
                        <p className="cart-product-name">{item.name}</p>
                      </div>

                      <p style={{ display: 'flex', alignItems: 'center' }}>
                        <span className="cart-product-unit-size">{item.unitSize}</span>
                        <div className="cart-vertical-line"></div>
                        <span className="cart-original-price">{item.unitPrice}</span>
                      </p>

                      <div className="cart-product-card-container">
                        <div className="price-quantity-container" style={{ display: 'flex', alignItems: 'center' }}>
                          <p style={{ marginRight: '10px' }}>
                            <strong className="cart-current-price">${price.toFixed(2)}/ea</strong>
                          </p>
                          <QuantitySelector
                            initialQuantity={item.quantity > 0 ? item.quantity : 0} // Réinitialise la quantité à 0 si nécessaire
                            onQuantityChange={(newQuantity) => updateQuantity(item.name, newQuantity)}
                          />
                          {/* Bouton pour supprimer l'article du panier */}
                          <button
                            onClick={() => handleRemoveFromCart(item.name)}
                            className="remove-button"
                            style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                          >
                            <FaTrash style={{ color: 'red', fontSize: '20px' }} />
                          </button>
                         
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className="click-outside" onClick={onClose}></div>
    </div>
  );
};


export default CartModal;
