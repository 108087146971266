// SubcategoryList.tsx

import React from 'react';
import './SubCategoriesList.css'; // Ajoutez des styles si nécessaire

interface Subcategory {
  label: string;
  subcategories: string[];
}

interface SubcategoryListProps {
  category: Subcategory;
  onSubcategoryClick: (subcategory: string) => void; // Ajouter la prop pour gérer le clic
}

const SubcategoryList: React.FC<SubcategoryListProps> = ({ category, onSubcategoryClick }) => {
  return (
    <div className="subcategories-container">
      <ul>
        {[...new Set(category.subcategories)].map((subcategory, index) => (
          <li key={index}>
            <button
              onClick={() => onSubcategoryClick(subcategory)}
              className="subcategory-link"
            >
              {subcategory}
            </button>
          </li>
        ))}
      </ul>

    </div>
  );
};

export default SubcategoryList;
