import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './DropdownMenu.css';
import { MenuData } from './DropdownData/MenuData';

interface DropdownProps {
  label: string;
  menuData: MenuData[]; // Utilisez l'interface MenuData pour le type
  departmentName: string; // Ajoutez departmentName comme prop
}

const Dropdown: React.FC<DropdownProps> = ({ label, menuData, departmentName }) => {
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();

  // Générer une URL avec departmentName et categoryLabel
  const generateUrl = (categoryLabel: string) => {
    // Remplace les espaces par des tirets et remplace `&` par `-`
    
  
    const formattedCategoryLabel = categoryLabel
      .toLowerCase()
      .replace(/ /g, '-')      // Remplace les espaces par des tirets
      .replace(/&/g, '-')       // Remplace les `&` par des tirets
      .replace(/-+/g, '-')      // Supprime les tirets consécutifs
      .replace(/^-|-$/g, '');    // Supprime les tirets en début et fin de chaîne
  
    return `/category/${formattedCategoryLabel}`;
  };
  const generateUrl1 = (categoryLabel: string) => {
    // Remplace les espaces par des tirets et remplace `&` par `-`
    const formattedCategoryLabel = categoryLabel
      .toLowerCase()
      .replace(/ /g, '-')      // Remplace les espaces par des tirets
      .replace(/&/g, '-')       // Remplace les `&` par des tirets
      .replace(/-+/g, '-')      // Supprime les tirets consécutifs
      .replace(/^-|-$/g, '');    // Supprime les tirets en début et fin de chaîne
    const formattedDepartmentName = departmentName
      .toLowerCase()
      .replace(/ /g, '-')     // Remplace les espaces par des tirets
      .replace(/&/g, '-')      // Remplace les `&` par des tirets
      .replace(/-+/g, '-')     // Supprime les tirets consécutifs
      .replace(/^-|-$/g, '');   // Supprime les tirets en début et fin de chaîne
  
    
  
    return `/${formattedDepartmentName}/${formattedCategoryLabel}`;
  };
  
  
  

  const handleMenuClick = (index: number) => {
    setActiveTab(index);
  };

  const generateColumns = (categories: (string | { label: string; subcategories: string[] })[]) => {
    const maxItemsPerColumn = 4;
    const columns = [];

    for (let i = 0; i < categories.length; i += maxItemsPerColumn) {
      columns.push(categories.slice(i, i + maxItemsPerColumn));
    }

    return columns;
  };

  return (
    <div className="dropdown-container">
      {menuData.length > 0 && (
        <>
          <div className="tabs">
            {menuData.map((tab, index) => (
              <button
                key={tab.label}
                className={`tab-button ${activeTab === index ? 'active-tab' : ''}`}
                onClick={() => handleMenuClick(index)}
              >
                {tab.label}
              </button>
            ))}
          </div>

          <hr className="hr-separator" />

          <div className="categories-grid">
            {generateColumns(menuData[activeTab]?.categories || []).map((group, groupIndex) => (
              <div key={groupIndex} className="category-column">
                {group.map((category, categoryIndex) => {
                  if (typeof category === 'string') {
                    return (
                      <NavLink
                        key={categoryIndex}
                        to={generateUrl(category)}
                        className={`category-link ${location.pathname.includes(category) ? 'active' : ''}`}
                      >
                        {category}
                      </NavLink>
                    );
                  } else {
                    return (
                      <NavLink
                        key={categoryIndex}
                        to={generateUrl(category.label)}
                        className={`category-link ${location.pathname.includes(category.label) ? 'active' : ''}`}
                      >
                        {category.label}
                      </NavLink>
                    );
                  }
                })}
              </div>
            ))}
          </div>

          <div className="see-all">
            <NavLink
              to={generateUrl1(menuData[activeTab]?.label)} // Utiliser generateUrl pour "See all"
              className="see-all-link"
            >
              See all {menuData[activeTab]?.label}
            </NavLink>
          </div>

        </>
      )}
    </div>
  );
};

export default Dropdown;
