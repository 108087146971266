import React from 'react';
import { useParams } from 'react-router-dom';

import { CategoryDataMap } from './categoryDataMap';
import CategoryPage from '../../Pages/CategoryPage ';

const CategoryPageWrapper: React.FC = () => {
  const { category } = useParams<{ category: string }>(); // Récupérer le paramètre de catégorie

  const categoryName = category?.replace('-', ' ') || ""; // Formatage du nom de la catégorie
  const categoryData = CategoryDataMap[category as keyof typeof CategoryDataMap] || []; // Utiliser un tableau vide si la catégorie est inexistante

  // Si aucune donnée n'est trouvée pour la catégorie, afficher un message par défaut
  if (categoryData.length === 0) {
    return <div>Aucun produit disponible pour cette catégorie.</div>;
  }

  // Déterminer la sous-catégorie si elle existe dans les données
  const categoryInfo = {
    label: categoryName,
    subcategories: categoryData.map((item: any) => item.subcategory).filter(Boolean) // Extraire les sous-catégories
  };

  return (
    <CategoryPage 
      categoryData={categoryData} // Les données de la catégorie
      categoryName={categoryName} // Le nom de la catégorie
      navItemLabel={categoryName} // Utiliser le même label pour l'élément de navigation
      category={categoryInfo} // Fournir les informations de la catégorie et sous-catégories
    />
  );
};

export default CategoryPageWrapper;
