import { Product } from "../Product";

const CarrotsData: Product[] = [
  
  {
    id: 1,
    name: 'Wild-Caught Tuna Salad',
    brand: 'BingsEpicerie',
    unitSize: '5oz',
    unitPrice: '$15.97/lb',
    price: '$4.99/ea',
    media: '/assets/SVG/deli1.png',
    subcategory: "Eggs",
    shopBy: ['EXPRESS'],
    isPopular: false,  // Exemple avec isPopular défini à false
  },
  {
    id: 2,
    name: 'Wild-Caught Tuna Salad',
    brand: 'BingsEpicerie',
    unitSize: '5oz',
    unitPrice: '$15.97/lb',
    price: '$4.99/ea',
    media: '/assets/SVG/deli1.png',
    subcategory: "Pasta",
    shopBy: ['Peak Quality'],
    isPopular: true,  // Exemple avec isPopular défini à true
  },
  {
    id: 3,
    name: 'Wild-Caught Tuna Salad',
    brand: 'BingsEpicerie',
    unitSize: '5oz',
    unitPrice: '$15.97/lb',
    price: '$4.99/ea',
    media: '/assets/SVG/deli1.png',
    subcategory: "Eggs",
    shopBy: ['EXPRESS'],
    isPopular: false,  // Exemple avec isPopular défini à false
  },
  {
    id: 4,
    name: 'Wild-Caught Tuna Salad',
    brand: 'BingsEpicerie',
    unitSize: '5oz',
    unitPrice: '$15.97/lb',
    price: '$4.99/ea',
    media: '/assets/SVG/deli1.png',
    subcategory: "Pasta",
    shopBy: ['Peak Quality'],
    isPopular: true,  // Exemple avec isPopular défini à true
  },
  {
    id: 5,
    name: 'Wild-Caught Tuna Salad',
    brand: 'BingsEpicerie',
    unitSize: '5oz',
    unitPrice: '$15.97/lb',
    price: '$4.99/ea',
    media: '/assets/SVG/deli1.png',
    subcategory: "Eggs",
    shopBy: ['EXPRESS'],
    isPopular: false,  // Exemple avec isPopular défini à false
  },
  {
    id: 6,
    name: 'Wild-Caught Tuna Salad',
    brand: 'BingsEpicerie',
    unitSize: '5oz',
    unitPrice: '$15.97/lb',
    price: '$4.99/ea',
    media: '/assets/SVG/deli1.png',
    subcategory: "Pasta",
    shopBy: ['Peak Quality'],
    isPopular: true,  // Exemple avec isPopular défini à true
  },
];

export default CarrotsData; // Export des données
