import React from 'react';
import Product, { Product as ProductType } from '../../../Categories/Product';
import './ProductList.css';

interface ProductListProps {
  products: ProductType[];
}

const ProductList: React.FC<ProductListProps> = ({ products }) => {
  return (
    <div className="filtered-products-section">
      {products.length > 0 ? (
        <div className="products-container">
          <Product products={products} />
        </div>
      ) : (
        <p>Aucun produit trouvé pour cette catégorie.</p>
      )}
    </div>
  );
};

export default ProductList;
