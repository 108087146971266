import React, { useState } from 'react';
import Breadcrumbs from '../Components/Categories/Breadcrumbs/Breadcrumbs';
import Product from '../Components/Categories/Product';
import SubcategoryList from '../Components/Categories/SubCategoriesList/SubCategoriesList';

interface CategoryPageProps {
  categoryData: any[]; // Les données des produits de la catégorie
  categoryName: string; // Le nom de la catégorie
  navItemLabel: string; // Le label de l'élément de navigation
  category: { label: string; subcategories: string[] }; // La catégorie avec les sous-catégories
}

const calculateTotalProducts = (data: any[], subcategory: string): number => {
  if (!subcategory) return data.length;
  return data.filter(product => product.subcategory === subcategory).length;
};

const CategoryPage: React.FC<CategoryPageProps> = ({ categoryData, categoryName, navItemLabel, category }) => {
  const [selectedSubcategory, setSelectedSubcategory] = useState<string>(''); // Sous-catégorie sélectionnée

  // Calculer le nombre total de produits en fonction de la sous-catégorie sélectionnée
  const totalProducts = calculateTotalProducts(categoryData, selectedSubcategory);

  const handleSubcategoryClick = (subcategory: string) => {
    // Met à jour la sous-catégorie sélectionnée
    setSelectedSubcategory(subcategory);
  };

  // Filtrer les produits en fonction de la sous-catégorie sélectionnée
  const filteredProducts = selectedSubcategory
    ? categoryData.filter(product => product.subcategory === selectedSubcategory)
    : categoryData; // Afficher tous les produits si aucune sous-catégorie n'est sélectionnée

  return (
    <main>
      {/* Appel du composant Breadcrumbs */}
      <Breadcrumbs 
        category={categoryName} // Passer le nom de la catégorie
        navItemLabel={navItemLabel} // Passer le label de l'élément de navigation
        totalProducts={totalProducts} // Passer le nombre total de produits
        subcategory={selectedSubcategory} // Passer la sous-catégorie sélectionnée (facultatif)
      />

      {/* Affichage de la liste des sous-catégories */}
      <SubcategoryList 
        category={category} 
        onSubcategoryClick={handleSubcategoryClick} // Passer la fonction de clic
      />

      {/* Affichage des produits ou message d'absence de produits */}
      {categoryData.length === 0 ? (
        <p>Aucun produit disponible pour cette catégorie.</p>
      ) : filteredProducts.length > 0 ? (
        <Product products={filteredProducts} />
      ) : (
        <p>Aucun produit disponible pour cette sous-catégorie.</p>
      )}
    </main>
  );
};

export default CategoryPage;
