import { MenuData } from "./MenuData";

export const ProduceData: MenuData[] = [
  {
    label: 'Fruits',
    image: '/assets/menu/cat.avif',
    categories: [
      'Apples',
      'Avocados',
      'Bananas',
      {
        label: 'Berries',
        subcategories: [
          'Cases',
          'Freshly Cut',
          'Whole',
        ]
      },
      'Citrus',
      'Dried Fruit & Nuts',
      'Flowers',
      'Grapes',
      'Melons',
      'Pears',
      'Pre-Cut',
      'Snack Packs',
      'Stone Fruit & Cherries',
      'Tropical & Specialty',
    ],
  },
  {
    label: 'Vegetables',
    image: '/assets/menu/cat.avif',
    categories: [
      'Asparagus & Artichokes',
      'Avocados',
      'Beans & Peas',
      'Broccoli & Cauliflower',
      'Brussels Sprouts & Cabbage',
      'Carrots',
      'Celery',
      'Corn',
      'Cucumbers',
      'Eggplant',
      'Flowers',
      'Fresh Herbs',
      'International',
      'Lettuce',
      'Local Farm Share',
      'Mushrooms',
      'Onions & Garlic',
      'Packaged Salads',
      'Peppers',
      'Potatoes & Yams',
      'Pre-Cut',
      'Root Vegetables',
      'Salad Mixes & Kits',
      'Snack Packs',
      'Specialty',
      'Spinach, Kale & Greens',
      'Sprouts & Microgreens',
      'Squash & Zucchini',
      'Tomatoes',
      'Wild & Foraged',
    ],
  },
];
