import { MenuData } from "./MenuData";

export const SnacksData: MenuData[] = [
  {
    label: 'Snacks',
    image: '/assets/menu/cat.avif',
    categories: [
      'Bars',
      'Cakes & Pastry',
      'Candy',
      'Chips',
      'Cookies',
      'Crackers',
      'Dried Fruit & Nuts',
      'Fresh Snacks & Snack Packs',
      'Granola',
      'Jerky',
      'Rice Cakes',
      'Salsa & Dips',
      'String Cheese & Minis',
    ],
  },
];
