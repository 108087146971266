import { MenuData } from "./MenuData";


export const DairyData: MenuData[] = [
  {
      label: 'Dairy',
      image: '/assets/menu/cat.avif',
      categories: [
          'Biscuits & Dough',
          'Butter & Margarine',
          'Chilled Pasta & Sauce',
          'Cream & Creamers',
          'Dips & Spreads',
          'Dressing & Condiments',
          'Eggs',
          'Hummus',
          'Lunches & Snack Packs',
          'Milk',
          'Milk, Plant-Based',
          'Packaged Cheese',
          'Packaged Meats',
          'Plant-Based & Vegetarian',
          'Pudding & Gelatin',
          'Salsa & Guacamole',
          'Sliced Cheese',
          'Sour Cream',
          'Yogurt',
      ],
  },
];
