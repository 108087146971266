import { MenuData } from "./MenuData";

export const SuppliesData: MenuData[] = [
  {
    label: 'Supplies',
    image: '/assets/menu/cat.avif',
    categories: [
      'Baby',
      'Cleaning',
      'Coffee Filters',
      'Cups',
      'First Aid',
      'Food Storage',
      'Hand Soap & Sanitizer',
      'Hardware',
      'Paper Products',
      'Personal Hygiene',
      'Pest Control',
      'Pet',
      'Plates & Bowls',
      'Trash Bags',
      'Utensils',
      
    ],
  },
];
