import React, { useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import DepartmentsSection from '../Components/DepartmentsSection/DepartmentsSection';
import ShopByMenu from '../Components/HomePage/MainContent/ShopBy/ShopByMenu';
import { ShopByData } from '../Components/HomePage/MainContent/ShopBy/ShopByData';

interface ShopByItem {
  id: number;
  label: string;
  color: string;
}

const DepartmentPage: React.FC = () => {
  const { departmentName } = useParams<{ departmentName: string }>();
  const [items] = useState<ShopByItem[]>(ShopByData);
  const [activeItemId, setActiveItemId] = useState<number | null>(null);
  
  // Vérification de la validité de departmentName
  if (!departmentName) {
    return <Navigate to="/404" replace />; // Redirige vers une page 404 ou une page d'accueil
  }

  const handleClick = (id: number) => {
    setActiveItemId(id);
  };

  return (
    <div className="department-page">
      <ShopByMenu
        items={items}
        activeItemId={activeItemId}
        onClick={handleClick}
      />
      <DepartmentsSection departmentName={departmentName} activeNavItem="Departments" />
    </div>
  );
};

export default DepartmentPage;
