import React, { createContext, useContext, useState, ReactNode, FC, useEffect } from 'react';

// Définir l'interface pour un élément du panier
interface CartItem {
  id: string; // Identifiant unique du produit
  name: string; // Nom du produit pour la correspondance
  quantity: number;
  price: number;
  media: string;
  brand: string;
  unitSize: string;
  unitPrice: string;
  shopBy: string[];
}

// Interface du contexte du panier
interface CartContextType {
  cartItems: CartItem[]; // Liste des éléments dans le panier
  addToCart: (item: CartItem, quantity: number) => void; // Ajouter un produit au panier
  updateQuantity: (itemName: string, quantity: number) => void; // Mettre à jour la quantité d'un produit dans le panier
  removeFromCart: (itemName: string) => void; // Supprimer un produit du panier
  itemCount: number; // Compter le nombre d'articles dans le panier
}

// Créer le contexte du panier
const CartContext = createContext<CartContextType | undefined>(undefined);

interface CartProviderProps {
  children: ReactNode;
}

export const CartProvider: FC<CartProviderProps> = ({ children }) => {
  const [cartItems, setCartItems] = useState<CartItem[]>([]); // État pour les éléments du panier
  const [itemCount, setItemCount] = useState(0); // Nombre d'articles uniques dans le panier

  // Charger le panier depuis localStorage lors du montage du composant
  useEffect(() => {
    const storedCartItems = localStorage.getItem('cartItems');
    if (storedCartItems) {
      setCartItems(JSON.parse(storedCartItems)); // Charger les éléments du panier
    }
  }, []);

  // Sauvegarder le panier dans localStorage à chaque changement
  useEffect(() => {
    if (cartItems.length > 0) {
      localStorage.setItem('cartItems', JSON.stringify(cartItems)); // Sauvegarder le panier dans le localStorage
    }
  }, [cartItems]);

  // Mettre à jour le nombre d'articles uniques
  useEffect(() => {
    setItemCount(cartItems.length);
  }, [cartItems]);

  // Ajouter un article au panier
  const addToCart = (item: CartItem, quantity: number) => {
    setCartItems((prevItems) => {
      const existingItem = prevItems.find((i) => i.name === item.name); // Utiliser `name` pour identifier les produits
      if (existingItem) {
        return prevItems.map((i) =>
          i.name === item.name ? { ...i, quantity: i.quantity + quantity } : i
        );
      }
      return [...prevItems, { ...item, quantity }]; // Ajouter un nouvel article au panier
    });
  };

  // Mettre à jour la quantité d'un produit dans le panier en utilisant `name`
  const updateQuantity = (productName: string, quantity: number) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.name === productName ? { ...item, quantity } : item // Utiliser `name` au lieu de `id`
      )
    );
  };
// CartContext.js
const removeFromCart = (productName: string) => {
  setCartItems((prevItems) =>
    prevItems.filter(item => item.name !== productName) // Supprimer l'élément du panier
  );
  // Réinitialiser la quantité du produit à 0 ici si nécessaire
  updateQuantity(productName, 0);  // Met à jour la quantité à 0
};


  // Obtenir la quantité d'un produit dans le panier
  const getCartItemQuantity = (productName: string) => {
    const item = cartItems.find((item) => item.name === productName); // Trouver l'élément avec le `name`
    return item ? item.quantity : 0;
  };

  return (
    <CartContext.Provider value={{ cartItems, addToCart, updateQuantity, removeFromCart, itemCount }}>
      {children}
    </CartContext.Provider>
  );
};

// Hook personnalisé pour utiliser le contexte du panier
export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};
