// deliSaladsData.ts

import { Product } from "../Product";


const MinuteMealsData: Product[] = [
  
    {
      id: 1,
      name: 'Wild-Caught Tuna Salad',
      brand: 'BingsEpicerie',
      unitSize: '5oz',
      unitPrice: '$15.97/lb',
      price: '$4.99/ea',
      media: '/assets/SVG/deli1.png',
      subcategory: "Eggs",
      shopBy: ['EXPRESS'], // Cela doit correspondre à 'EXPRESS'
      isPopular: true,
    },
    {
      id: 2,
      name: 'Wild-Caught Tuna Salad',
      brand: 'BingsEpicerie',
      unitSize: '5oz',
      unitPrice: '$15.97/lb',
      price: '$4.99/ea',
      media: '/assets/SVG/deli1.png',
      subcategory: "Pasta",
      shopBy: ['Peak Quality'], // Cela doit correspondre à 'Peak Quality'
      isPopular: true,
    },
  ];
  


export default MinuteMealsData; // Export des données
