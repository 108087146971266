import React, { useState } from 'react';
import './Product.css';
import QuantitySelector from './QuantitySelector/QuantitySelector';
import SignInModal from '../HomePage/Header/Account/SignInModal/SignInModal';
import { ShopByData } from '../HomePage/MainContent/ShopBy/ShopByData';
import { useCart } from '../HomePage/CartContext';

export interface Product {
  id: number;
  name: string;
  brand: string;
  unitSize: string;
  unitPrice: string;
  price: string;
  media: string;
  shopBy: string[];
  subcategory: string;
  isPopular?: boolean;  // Ajouter une propriété isPopular
}

interface ProductProps {
  products: Product[];
}

const Product: React.FC<ProductProps> = ({ products }) => {
  const { addToCart, updateQuantity, removeFromCart } = useCart(); // Ajout de la méthode removeFromCart
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAllProducts, setShowAllProducts] = useState(false);
  const [notification, setNotification] = useState<string | null>(null);
  const [quantities, setQuantities] = useState<{ [key: string]: number }>({}); // Utilisation du `name` comme clé

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const productsPerRow = 6;
  const displayedProducts = showAllProducts ? products : products.slice(0, productsPerRow);

  const toggleShowAllProducts = () => setShowAllProducts(!showAllProducts);

  const getShopByInfo = (shopBy: string) => ShopByData.find(item => item.label === shopBy) || null;

  const handleAddToCart = (product: Product, quantity: number) => {
    const cartItem = {
      id: product.name, // Utilisation du `name` comme identifiant
      name: product.name,
      quantity,
      price: parseFloat(product.price),
      media: product.media,
      brand: product.brand,
      unitSize: product.unitSize,
      unitPrice: product.unitPrice,
      shopBy: product.shopBy,
    };
    addToCart(cartItem, quantity);
    setNotification(`${quantity} x ${product.name} ajouté au panier !`);
    setTimeout(() => setNotification(null), 3000);
  };

  const handleQuantityChange = (productName: string, quantity: number) => {
    setQuantities((prev) => ({ ...prev, [productName]: quantity }));
  
    const product = products.find((p) => p.name === productName);
    if (product) {
      if (quantity > 0) {
        const cartItemExists = quantities[productName] && quantities[productName] > 0;
  
        if (!cartItemExists) {
          handleAddToCart(product, quantity); // Ajoute le produit au panier s'il n'existe pas
        }
  
        // Met à jour la quantité dans le panier
        updateQuantity(productName, quantity); // Utilisation du `name` pour la mise à jour
      } else {
        // Réinitialiser la quantité dans le panier si elle est à zéro
        removeFromCart(productName); // Supprimer le produit du panier si la quantité est zéro
      }
    }
  };
  

 

  return (
    <div>
      {notification && <div className="notification">{notification}</div>}
      <div className="product-list">
        {displayedProducts.length > 0 ? (
          displayedProducts.map((product, index) => (
            <div className={`product-item ${index !== products.length - 1 ? 'border-right' : ''}`} key={product.id}>
              <div className="flex justify-center mb-4">
                {product.media.toLowerCase().endsWith('.mp4') ? (
                  <video src={product.media}  className="product-image" />
                ) : (
                  <img src={product.media} alt={product.name} className="product-image" />
                )}
              </div>

              <div className="shop-by-labels">
                {product.shopBy.map((shopBy, i) => {
                  const shopByInfo = getShopByInfo(shopBy);
                  return (
                    shopByInfo && (
                      <span key={shopBy} style={{ color: shopByInfo.color, fontSize: 14 }}>
                        {shopByInfo.label}
                        {i !== product.shopBy.length - 1 && <span className="vertical-divider"> | </span>}
                      </span>
                    )
                  );
                })}
              </div>

              <div>
                <p className="product-brand">{product.brand}</p>
                <p className="product-name">{product.name}</p>
              </div>
              <div>
                <p style={{ display: 'flex', alignItems: 'center' }}>
                  <span className="product-unit-size">{product.unitSize}</span>
                  <div className="vertical-line"></div>
                  <span className="original-price">{product.unitPrice}</span>
                </p>
              </div>
              <div className="product-card-container">
                <p>
                  <strong className="current-price">{product.price}</strong>
                </p>
                <div className="right-container">
                  <QuantitySelector
                    initialQuantity={quantities[product.name] || 0} // Réinitialisation à 0 après suppression
                    onQuantityChange={(quantity) => handleQuantityChange(product.name, quantity)}
                  />
                </div>
              </div>
             
            </div>
          ))
        ) : (
          <p>Aucun produit disponible.</p>
        )}
        <SignInModal isOpen={isModalOpen} onClose={handleCloseModal} />
      </div>
    </div>
  );
};

export default Product;
