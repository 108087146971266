import { Product } from "../Product";

const AppetizersData: Product[] = [
  {
    id: 1,
    name: "Beef Franks in Blankets",
    brand: "BingsEpicerie Frozen",
    unitSize: "12ct",
    unitPrice: "$31.09/lb",
    price: "$13.99/ea",
    media: "/assets/SVG/appetizer.png",
    shopBy: ['EXPRESS'],
    subcategory: "Dumplings & Buns",
    isPopular: true,  // Ajout de la propriété isPopular
  },
  {
    id: 2,
    name: 'Chicken Fingers Family Pack',
    brand: 'BingsEpicerie FrozenCrispy',
    unitSize: '5oz',
    unitPrice: '$15.97/lb',
    price: '$4.99/ea',
    media: '/assets/SVG/appetizer.png',
    shopBy: ['EXPRESS'],
    subcategory: "Ready-to-Bake",
    isPopular: false,  // Vous pouvez définir cette propriété comme false si ce n'est pas populaire
  },
];

export default AppetizersData; // Export des données
