import React from 'react';
import './Breadcrumbs.css';

import { NavData1 } from '../../HomePage/Header/Navbar/NavData';
import { NavData2 } from '../../HomePage/Header/Navbar2/NavData2';
import { PreparedData } from '../../Dropdown/DropdownData/PreparedData';
import { ProduceData } from '../../Dropdown/DropdownData/ProduceData';
import { MenuData } from '../../Dropdown/DropdownData/MenuData';

// Combiner toutes les données de menu
const combinedMenuData: MenuData[] = [
  ...ProduceData,
  ...PreparedData,
];

// Combiner toutes les données de navigation
const combinedNavData = [...NavData1, ...NavData2];

interface BreadcrumbItem {
  label: string;
  path: string;
}

interface BreadcrumbsProps {
  category: string; // Catégorie principale
  navItemLabel: string; // Label de l'élément de navigation
  totalProducts: number; // Nombre total de produits
  subcategory?: string; // Sous-catégorie sélectionnée (facultative)
}




const generateBreadcrumbs = (category: string, navItemLabel: string, subcategory?: string): BreadcrumbItem[] => {
  const breadcrumbs: BreadcrumbItem[] = [];

  // Rechercher l'élément de navigation correspondant au navItemLabel
  const matchingNavItem = combinedNavData.find(item => item.label.toLowerCase() === navItemLabel.toLowerCase());

  if (matchingNavItem) {
    // Ajouter l'élément de navigation au fil d'Ariane
    breadcrumbs.push({
      label: matchingNavItem.label,
      path: `/category/${matchingNavItem.label.toLowerCase().replace(/\s+/g, '-')}` // Chemin avec "category"
    });
  } else {
    console.warn(`Aucun élément de navigation trouvé pour ${navItemLabel}`);
  }

  // Fonction récursive pour rechercher dans les catégories et sous-catégories
  const findCategory = (categories: any[], category: string): any => {
    const normalizedCategory = category.toLowerCase().replace(/\s+/g, '-');
    for (const cat of categories) {
      if (typeof cat === 'string' && cat.toLowerCase().replace(/\s+/g, '-') === normalizedCategory) {
        return cat;
      } else if (typeof cat === 'object' && cat.label.toLowerCase().replace(/\s+/g, '-') === normalizedCategory) {
        return cat;
      } else if (typeof cat === 'object' && cat.subcategories) {
        const foundInSubcategories = findCategory(cat.subcategories, category);
        if (foundInSubcategories) {
          return { ...cat, foundSubcategory: foundInSubcategories };
        }
      }
    }
    return null;
  };

  // Rechercher dans les données de menu combinées pour la catégorie
  for (const menuItem of combinedMenuData) {
    const matchingCategory = findCategory(menuItem.categories, category);

    if (matchingCategory) {
      // Ajouter la catégorie principale au fil d'Ariane
      breadcrumbs.push({
        label: menuItem.label,
        path: `/category/${menuItem.label.toLowerCase().replace(/\s+/g, '-')}` // Chemin avec "category"
      });

      // Ajouter la catégorie trouvée (principale ou sous-catégorie)
      breadcrumbs.push({
        label: category, // Utiliser seulement le nom de la catégorie
        path: `/category/${category.toLowerCase().replace(/\s+/g, '-')}` // Chemin avec "category"
      });

      // Si la catégorie est un objet et contient une sous-catégorie trouvée
      if (matchingCategory.foundSubcategory) {
        breadcrumbs.push({
          label: matchingCategory.foundSubcategory,
          path: `/category/${menuItem.label.toLowerCase().replace(/\s+/g, '-')}/${matchingCategory.foundSubcategory.toLowerCase().replace(/\s+/g, '-')}` // Chemin avec "category"
        });
      }

      // Ajouter la sous-catégorie si elle est fournie
      if (subcategory) {
        breadcrumbs.push({
          label: subcategory,
          path: `/category/${category.toLowerCase().replace(/\s+/g, '-')}/${subcategory.toLowerCase().replace(/\s+/g, '-')}` // Chemin avec "category"
        });
      }

      break; // Sortir de la boucle une fois la correspondance trouvée
    }
  }

  // Retourner le fil d'Ariane généré
  return breadcrumbs.length > 0 ? breadcrumbs : [{ label: 'Catégorie non trouvée', path: '#' }];
};

// Composant Breadcrumbs
const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ category, navItemLabel, totalProducts, subcategory }) => {
  const breadcrumbs = generateBreadcrumbs(category, navItemLabel, subcategory);

  return (
    <div className='bread'>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          {breadcrumbs.map((item, index) => (
            <li
              key={index}
              className={`breadcrumb-item ${index === breadcrumbs.length - 1 ? 'active' : ''}`}
            >
              {index === breadcrumbs.length - 1 ? (
                <>
                  {item.label} <span className="Breadcrumbs_counter__hUeTs">({totalProducts})</span>
                </>
              ) : (
                <a href={item.path}>{item.label}</a>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};

export default Breadcrumbs;
