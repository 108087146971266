import { MenuData } from "./MenuData";




export const MeatSeafoodsData: MenuData[] = [
    {
        label: 'Meat',
        image: '/assets/menu/cat.avif',
        categories: [
            "Beef",
            "Ground & Burgers",
            "Lamb",
            "Meat Alternatives",
            "Pork",
            "Pre-Cut",
            "Prepped",
            "Sausage & Hot Dogs",
            "Veal & Specialty",
        ],
    },
    {
        label: 'Poultry',
        image: '/assets/menu/cat.avif',
        categories: [
            "Chicken",
            "Turkey",
            "Duck & Specialty",
            "Prepped",
            "Fully Cooked",
            "Meat Alternatives",
        ],
    },
    {
        label: 'Seafoods',
        image: '/assets/menu/cat.avif',
        categories: [
            "Crab",
            "Fish Fillets",
            "Fish Steaks",
            "Fish Whole",
            "Lobster",
            "Mussels & Clams",
            "Oysters",
            "Salmon",
            "Scallops",
            "Shrimp",
            "Specialty",
        ],
    },

];
