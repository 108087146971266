import React, { useEffect, useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import { useLocation } from "react-router-dom"; // Import pour détecter la route

interface ShopByItem {
  id: number;
  label: string;
  color: string;
}

interface ShopByMenuProps {
  items: ShopByItem[];
  activeItemId: number | null;
  onClick: (id: number) => void;
}

const ShopByMenu: React.FC<ShopByMenuProps> = ({ items, activeItemId, onClick }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [userToggled, setUserToggled] = useState(false);
  const location = useLocation(); // Hook pour obtenir la route actuelle

  // Cacher le menu sur les autres pages
  const isHomePage = location.pathname === "/";

  useEffect(() => {
    const handleScroll = () => {
      if (!userToggled) {
        setIsVisible(window.scrollY <= 50);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [userToggled]);

  const toggleVisibility = () => {
    setUserToggled(true);
    setIsVisible((prev) => !prev);
  };

  if (!isHomePage) {
    return null; // Ne rien afficher si ce n'est pas la page d'accueil
  }

  return (
    <div className="shop-by-menu" style={{ display: "flex", justifyContent: "flex-end" }}>
      <ul className="shop-by-items">
        {isVisible &&
          items.map((item) => (
            <li
              key={item.id}
              className={`shop-by-item ${activeItemId === item.id ? "active" : ""}`}
              onClick={() => onClick(item.id)}
              style={{
                backgroundColor: activeItemId === item.id ? item.color : "transparent",
                display: "inline-block",
                marginRight: "10px",
                padding: "10px 15px",
                cursor: "pointer",
              }}
            >
              <span
                style={{
                  color: activeItemId === item.id ? "#ffffff" : item.color,
                }}
              >
                {item.label}
              </span>
            </li>
          ))}
        <button onClick={toggleVisibility} className="toggle-shopby-btn">
          Shop by
          <FaChevronRight
            style={{ marginRight: "10px", transform: isVisible ? "rotate(90deg)" : "rotate(0deg)" }}
          />
        </button>
      </ul>
    </div>
  );
};

export default ShopByMenu;
