import { Product } from "../Product";

const deliSaladsData: Product[] = [
  {
    id: 1,
    name: 'produit2',
    brand: 'BingsEpicerie',
    unitSize: '5oz',
    unitPrice: '$15.97/lb',
    price: '$4.99/ea',
    media: '/assets/videos/vegetables/2.MP4',
    subcategory: "Whole",
    shopBy: ['Sale', 'Kosher'],
    isPopular: true,  // Ajout de la propriété isPopular
  },
  {
    id: 2,
    name: 'Wild-Caught Tuna Salad',
    brand: 'BingsEpicerie',
    unitSize: '5oz',
    unitPrice: '$15.97/lb',
    price: '$4.99/ea',
    media: '/assets/SVG/deli1.png',
    subcategory: "Whole",
    shopBy: ['Local'],
    isPopular: false,  // Exemple avec isPopular défini à false
  },
  {
    id: 3,
    name: 'produit3',
    brand: 'BingsEpicerie',
    unitSize: '5oz',
    unitPrice: '$15.97/lb',
    price: '$4.99/ea',
    media: '/assets/videos/vegetables/4.MP4',
    subcategory: "Whole",
    shopBy: ['Local'],
    isPopular: true,  // Exemple avec isPopular défini à true
  },
  {
    id: 4,
    name: 'produit4',
    brand: 'BingsEpicerie',
    unitSize: '5oz',
    unitPrice: '$15.97/lb',
    price: '$4.99/ea',
    media: '/assets/videos/Food Recipe Instagram Post/6.png',
    subcategory: "Whole",
    shopBy: ['Local'],
    isPopular: false,  // Exemple avec isPopular défini à false
  },
];

export default deliSaladsData; // Export des données
