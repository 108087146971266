// MenuData.ts (ou un fichier approprié)

import { BakeryData } from "./BakeryData";
import { BeerCiderData } from "./BeerCiderData";
import { BeveragesData } from "./BeveragesData";
import { DairyData } from "./DairyData";
import { DealsSaleSelectedData } from "./DealsSaleSelectedData";
import { FreshData } from "./FreshData";
import { FrozenData } from "./FrozenData";
import { GroceryData } from "./GroceryData";
import { MealsData } from "./MealsData";
import { MeatSeafoodsData } from "./MeatSeafoodsData";
import { PantryData } from "./PantryData";
import { PreparedData } from "./PreparedData";
import { ProduceData } from "./ProduceData";
import { SnacksData } from "./SnacksData";
import { SuppliesData } from "./SuppliesData";
import { WineAndSpiritsData } from "./WineAndSpiritsData";





export const menuDataMapping = {
  'Prepared & Deli': PreparedData,
  'Produce': ProduceData,
  'Dairy': DairyData,
  'Meat & Seafood': MeatSeafoodsData,
  'Bakery': BakeryData,
  'Frozen': FrozenData,
  'Grocery': GroceryData,
  'Wine & Spirits': WineAndSpiritsData,
  'Deals & Sale': DealsSaleSelectedData,
  'Pantry': PantryData,
  'Supplies': SuppliesData,

  'Beer & Cider': BeerCiderData,
  'Beverages': BeveragesData,
  'Meals': MealsData,
  'Fresh': FreshData,
  'Snacks': SnacksData,

  // Ajoutez d'autres associations si nécessaire
};
