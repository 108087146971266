import { MenuData } from "./MenuData";

export const DealsSaleSelectedData: MenuData[] = [
  {
    label: 'Fresh Deals',
    image: '/assets/menu/cat.avif',
    categories: [],
  },
  {
    label: 'Sale',
    image: '/assets/menu/cat.avif',
    categories: [],
  },
];
