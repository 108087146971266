import React, { useState, useEffect } from 'react';
import SignInModal from '../Account/SignInModal/SignInModal';
import { itemsToSearch } from './itemsToSearch'; // Importer la liste des éléments
import { SearchBarProps, AccountSectionProps, CartButtonProps } from './interfaces'; // Import des interfaces
import './TopLine.css'; // Import CSS for styling
import CreateAccount from '../Account/CreateAccount/CreateAccount';
import CartModal from '../../Cart';
import { useCart } from '../../CartContext';
import ModalWindow from '../Delivery/Delivery';

interface TopLineProps {
  location: string;
  onLocationChange: (newLocation: string) => void;
}

const TopLine: React.FC<TopLineProps> = ({ location, onLocationChange }) => {
  
  const [isAddressModalOpen, setIsAddressModalOpen] = useState<boolean>(false);

  const [isSignInModalOpen, setIsSignInModalOpen] = useState<boolean>(false);
  const [isCreateAccountModalOpen, setIsCreateAccountModalOpen] = useState<boolean>(false);
  const [isCartModalOpen, setIsCartModalOpen] = useState<boolean>(false);
  const { cartItems } = useCart(); // Récupère les articles du panier depuis le contexte
  const [searchValue, setSearchValue] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 992);

  const openAddressModal = () => setIsAddressModalOpen(true);
  const closeAddressModal = () => setIsAddressModalOpen(false);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);

    if (value) {
      const results = itemsToSearch.filter(item =>
        item.toLowerCase().includes(value.toLowerCase())
      );
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  const clearSearch = () => {
    setSearchValue('');
    setSearchResults([]);
  };

  const toggleLocation = () => {
    const newLocation = location === 'Home' ? 'Residential' : 'Home';
    onLocationChange(newLocation);
  };

  const handleSearchFocus = () => setIsFocused(true);
  const handleSearchBlur = () => {
    if (!searchValue) setIsFocused(false);
  };

  // Render TopLine only if not on mobile
  if (isMobile) {
    return null; // Hide the TopLine component on mobile devices
  }

  return (
    <div className="TopLine_layout_start">
      <div className="flex items-center justify-between w-full">
        <Logo />

        <div className="border-l border-gray-300 h-8 mx-4" />
        <DeliverySection onOpenAddressModal={openAddressModal} />
        <SearchBar
          searchValue={searchValue}
          onChange={handleSearchChange}
          onFocus={handleSearchFocus}
          onBlur={handleSearchBlur}
          clearSearch={clearSearch}
          isFocused={isFocused}
        />
        <LocationSwitcher location={location} toggleLocation={toggleLocation} />
        <AccountSection
          isSignedIn={false}
          onSignInClick={() => setIsSignInModalOpen(true)}
          onCreateAccountClick={() => setIsCreateAccountModalOpen(true)}
        />
        <CartButton
          cartItemCount={cartItems.length}
          onClick={() => setIsCartModalOpen(true)}
        />
      </div>

      {/* Modals */}
      <SignInModal isOpen={isSignInModalOpen} onClose={() => setIsSignInModalOpen(false)} />
      <CreateAccount isOpen={isCreateAccountModalOpen} onClose={() => setIsCreateAccountModalOpen(false)} />
      <CartModal isOpen={isCartModalOpen} onClose={() => setIsCartModalOpen(false)} />
      {isAddressModalOpen && <ModalWindow onClose={closeAddressModal} />}

      {searchResults.length > 0 && (
        <div className="search-results">
          {searchResults.map((result, index) => (
            <div key={result} className="search-result-item">
              {result}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

// Logo Component
export const Logo: React.FC = () => (
  <div data-testid="logo" className="TopLine_logo_wrapper" role="group" tabIndex={0}>
    <a className="TopLine_logo" href="#">
      <img src="/assets/SVG/logo2.png" alt="Freshdirect logo" className="TopLine_logo_image" />
    </a>
  </div>
);

// Delivery Section Component
export const DeliverySection: React.FC<{ onOpenAddressModal: () => void }> = ({ onOpenAddressModal }) => (
  <div className="TopLine_delivery" data-testid="delivery-section">
    <p className="TopLine_delivery_title">Delivery</p>
    <button className="Button_button Button_small Button_underline_light Address_link" onClick={onOpenAddressModal}>
      Enter your address
    </button>
  </div>
);

// LocationSwitcher Component
export const LocationSwitcher: React.FC<{ location: string; toggleLocation: () => void }> = ({ location, toggleLocation }) => (
  <div className="button-content" onClick={toggleLocation} role="button" tabIndex={0}>
    <span className="text-sm font-bold mr-2">Go to</span>
    <div>
      <span className="text-sm font-normal">{location}</span>
      <span className="ml-2">›</span>
    </div>
  </div>
);


// SearchBar Component
export const SearchBar: React.FC<SearchBarProps> = ({
  searchValue, onChange, onFocus, onBlur, clearSearch, isFocused
}) => (
  <form className="searchbar-form">
    <div className="searchbar-input-wrapper">
      <input
        type="search"
        value={searchValue}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder="Search"
        className={`searchbar-input ${isFocused ? 'focused' : ''}`}
      />
      
    </div>
  </form>
);

// Account Section Component
export const AccountSection: React.FC<AccountSectionProps & { onCreateAccountClick: () => void, onSignInClick: () => void }> = ({ isSignedIn, onSignInClick, onCreateAccountClick }) => (
  <div className=" flex account-section">
    <p className="text-sm font-bold account-title">Account</p>
    <div className=" ml-2">
      {!isSignedIn ? (
        <>
          <button className="account-link" onClick={onSignInClick}>Sign in</button>
          <span className="text-sm mx-0.5 or-text">or</span>
          <button className="account-link" onClick={onCreateAccountClick}>Create an Account</button>
        </>
      ) : (
        <p className="text-sm font-normal">Welcome Back!</p>
      )}
    </div>
  </div>
);


// CartButton Component
export const CartButton: React.FC<{ cartItemCount: number; onClick: () => void }> = ({ cartItemCount, onClick }) => (
  <button
    className="cart-button relative"
    aria-label={`Your Bag with ${cartItemCount} items`}
    onClick={onClick}
  >
    <img src="/assets/SVG/panier.png" alt="Cart Icon" className="cart-icon" />
    {cartItemCount > 0 && (
      <span className="Cart_cart_counter__3IczK">
        {cartItemCount}
      </span>
    )}
  </button>
);

export default TopLine;
