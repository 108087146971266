import React from 'react';
import { Link } from 'react-router-dom';
import { menuDataMapping } from '../Dropdown/DropdownData/menuDataMapping';
import './DepartmentsSection.css';
import { CategoryDataMap } from '../Categories/categoryDataMap';
import { Product } from '../Categories/Product';

interface Category {
  label: string;
  subcategories?: string[];
}

interface MenuData {
  label: string;
  categories: (string | Category)[];
  image: string; // chemin vers l'image
}


interface DepartmentsSectionProps {
  activeNavItem: string;
  departmentName: string;
}

const DepartmentsSection: React.FC<DepartmentsSectionProps> = ({ activeNavItem, departmentName }) => {
  const formattedDepartmentName = departmentName
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' & ');

  const departmentData = menuDataMapping[formattedDepartmentName as keyof typeof menuDataMapping];

  if (!departmentData || !Array.isArray(departmentData)) {
    return (
      <div className="error-message">
        Aucun département trouvé pour "{departmentName}". Veuillez vérifier le format ou la disponibilité des données.
      </div>
    );
  }

  // Extraire les labels sous forme de chaînes de caractères
  const allLabelStrings = departmentData.map(section => section.label);

  // Construction du dernier label
  const lastLabel = allLabelStrings.pop();

  // Construction des labels avec "and" avant le dernier élément
  const labelsWithComma = allLabelStrings.length
    ? `${allLabelStrings.join(', ')} and ${lastLabel}`
    : lastLabel;

  // Construction des éléments <Link> avec les labels
  const labelsWithLinks = departmentData.map(section => (
    <Link
      key={section.label}
      to={`/${section.label.replace(/ /g, '-').toLowerCase()}`}
      className="main-label-link"
    >
      {section.label}
    </Link>
  ));

  const sectionPairs = [];
  for (let i = 0; i < departmentData.length; i += 2) {
    sectionPairs.push(departmentData.slice(i, i + 2));
  }

  return (
    <div className="departments-section">
      <div className="main-department-header">
        <h1 className="main-department-title">
          Ready-to-eat: {labelsWithComma}
        </h1>
      </div>
      <hr className="section-divider" />

      {sectionPairs.map((pair, pairIndex) => (
        <div key={pairIndex} className="departments-row">
          {pair.map((section: MenuData, sectionIndex) => (
            <div
              key={sectionIndex}
              className={`department-section ${sectionIndex % 2 === 0 ? 'image-right' : 'image-left'}`}
            >
              <h2 className="department-title">{section.label}</h2>
              <div className="categories-container">
                <div className="categories-list">
                  {section.categories.slice(0, 6).map((category, catIndex) => (
                    <span key={catIndex} className="category-item">
                      {typeof category === 'string' ? category : category.label}
                      {catIndex < 5 && ', '}
                    </span>
                  ))}
                </div>
                <Link
                  to={`/${departmentName.replace(/ /g, '-').toLowerCase()}/${section.label.replace(/ /g, '-').toLowerCase()}`}
                  className="view-all-button"
                >
                  View All
                </Link>

                {/* Affichage de l'image */}
                {section.image && (
                  <img
                    src={section.image}
                    alt={section.label}
                    className="department-image"
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      ))}

    </div>
  );
};

export default DepartmentsSection;
