import { MenuData } from "./MenuData";

export const WineAndSpiritsData: MenuData[] = [
  {
    label: 'Wine',
    image: '/assets/menu/cat.avif',
    categories: [
      'Red Wine',
      'White Wine',
      'Sparkling Wine',
      'Rosé Wine',
      'Fortified & Sweet',
      'Cider',
      'Sake',
      'Select Sips',
      'Orange Wine',
      'See all Wine',
    ],
  },
  {
    label: 'Spirits',
    image: '/assets/menu/cat.avif',
    categories: [
      'Whiskey',
      'Vodka',
      'Tequila & Mezcal',
      'Gin',
      'Cognac & Brandy',
      'Rum',
      'Liqueurs',
      'Vermouth & Bitters',
      'Cocktails & Mixers',
      'See all Spirits',
    ],
  },
];
