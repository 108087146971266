// NavData.ts
export interface NavItem {
  icon: string;
  label: string;
}

export const NavData1: NavItem[] = [
  {
    icon: '/assets/SVG/icon2.svg',
    label: 'Prepared & Deli',
  },
  {
    icon: '/assets/SVG/icon3.svg',
    label: 'Produce',
  },
  {
    icon: '/assets/SVG/icon4.svg',
    label: 'Meat & Seafood',
  },
  {
    icon: '/assets/SVG/icon5.svg',
    label: 'Dairy',
  },
  {
    icon: '/assets/SVG/icon6.svg',
    label: 'Bakery',
  },
  {
    icon: '/assets/SVG/icon7.svg',
    label: 'Frozen',
  },
  {
    icon: '/assets/SVG/icon8.svg',
    label: 'Grocery',
  },
  {
    icon: '/assets/SVG/icon9.svg',
    label: 'Wine & Spirits',
  },
  {
    icon: '/assets/SVG/icon10.svg',
    label: 'High Holidays',
  },
  {
    icon: '/assets/SVG/icon12.svg',
    label: 'Fall',
  },
  {
    icon: '/assets/SVG/icon11.svg',
    label: 'Deals & Sale',
  },
];
