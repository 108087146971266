import { MenuData } from "./MenuData";

export const MealsData: MenuData[] = [
  {
    label: 'Meals',
    image: '/assets/menu/cat.avif',
    categories: [
      'Appetizers',
      'Breakfast',
      'Dessert',
      'Entrées',
      'Green Salads',
      'Pizza',
      'Sandwiches',
      'Sides',
      'Soup',
      'Sushi',
    ],
  },
];
