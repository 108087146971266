import React, { useState } from 'react';
import { Button, IconButton, TextField, Checkbox, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import './Delivery.css';
import SignInModal from '../Account/SignInModal/SignInModal';

interface ModalWindowProps {
  onClose: () => void;
}

const ModalWindow: React.FC<ModalWindowProps> = ({ onClose }) => {
  const [isSignInModalOpen, setSignInModalOpen] = useState(false);

  const handleOpenSignInModal = () => {
    console.log('Opening SignInModal'); // Pour vérifier que la fonction est appelée
    setSignInModalOpen(true);
  };

  const handleCloseSignInModal = () => {
    setSignInModalOpen(false);
  };

  return (
    <div>
      {/* Overlay sombre */}
      <div className="custom-overlay" onClick={onClose}></div>

      {/* Conteneur principal du modal */}
      <div className="custom-modal">
        <div className="custom-modal-content">
          <div className="custom-modal-header">
            <Typography variant="h6" className="custom-modal-title">
              Enter your address for delivery
            </Typography>
            <IconButton onClick={onClose} aria-label="close" className="custom-modal-close-button">
              <CloseIcon />
            </IconButton>
          </div>

          <div className="custom-modal-body">
            <form className="custom-modal-form">
              <Typography variant="body1" className="custom-modal-subtitle">
                Check to see if delivery is available in your area.
              </Typography>

              <div className="custom-modal-form-group">
                <TextField
                  label="Address"
                  variant="outlined"
                  fullWidth
                  className="custom-modal-input"
                />
                <TextField
                  label="Apt/Unit"
                  variant="outlined"
                  fullWidth
                  className="custom-modal-input"
                />
              </div>

              <div className="custom-modal-form-options">
                <Checkbox className="custom-modal-checkbox" />
                <label className="custom-modal-label">Yes, this is a business or school address</label>
              </div>

              <Button
                variant="outlined"
                color="primary"
                fullWidth
                className="custom-modal-apply-button"
              >
                Apply
              </Button>
            </form>

            <div className="custom-modal-separator"></div>

            <Button
              className="custom-modal-pickup-button"
              variant="text"
              fullWidth
            >
              Pick up at <span className="custom-modal-pickup-address">2 Saint Anns Ave, Bronx NY 10454</span>
            </Button>

            <Typography className="custom-modal-signin-text">
              Or sign in to add, edit, or view your address
            </Typography>

            <Button
              className="custom-modal-signin-button"
              variant="contained"
              fullWidth
              onClick={handleOpenSignInModal} // Ouvrir le modal de connexion
            >
              Sign in
            </Button>

            <div className="custom-modal-contact">
              Still having trouble? Please contact our Customer Service team at&nbsp;
              <a href="tel:1-866-283-7374">1-866-283-7374</a>
            </div>
          </div>
        </div>
      </div>

      {/* Composant SignInModal */}
      <SignInModal isOpen={isSignInModalOpen} onClose={handleCloseSignInModal} />
    </div>
  );
};

export default ModalWindow;
