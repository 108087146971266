// data.js
export const ShopByData = [
  { id: 1, label: 'EXPRESS', color: '#c5521c' },
    { id: 2, label: "Peak Quality", color: '#298321' },
    { id: 3, label: "New" ,color: '#000'},
    { id: 4, label: "Sale" , color:'#000'},
    { id: 5, label: "Gluten-Free",color:'#000' },
    { id: 6, label: "Organic", color:'#000' },
    { id: 7, label: "Kosher",color:'#000' },
    { id: 8, label: "Local",color:'#000' },
    
  ];
