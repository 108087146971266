import { MenuData } from "./MenuData";

export const BeveragesData: MenuData[] = [
  {
    label: 'Hot Beverages',
    image: '/assets/menu/cat.avif',
    categories: [
      'Cocoa',
      'Coffee',
      'K-Cups & Pods',
      'Tea',
      'See all Hot Beverages',
    ],
  },
  {
    label: 'Cold Beverages',
    image: '/assets/menu/cat.avif',
    categories: [
      'Cocktail Mix',
      'Coffee Drinks',
      'Energy',
      'Functional Beverages',
      'Instant Mix',
      'Juice',
      'Kombucha',
      'Nutrition',
      'Soda',
      'Sports',
      'Tea',
      'Water & Seltzer',
      'See all Cold Beverages',
    ],
  },
];
