import React, { useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // Import des icônes de flèche
import './PopularProduct.css';

import { Product } from '../../../Categories/Product';
import AppetizersData from '../../../Categories/CategorieData/AppetizersData';
import CarrotsData from '../../../Categories/CategorieData/CarrotsData';
import deliSaladsData from '../../../Categories/CategorieData/DeliSaladsData';
import MinuteMealsData from '../../../Categories/CategorieData/MinuteMealsData';
import QuantitySelector from '../../../Categories/QuantitySelector/QuantitySelector';
import { useCart } from '../../CartContext';
import { ShopByData } from './ShopByData';
import { Link } from 'react-router-dom';

const allProducts = [
    ...AppetizersData,
    ...CarrotsData,
    ...deliSaladsData,
    ...MinuteMealsData,
];

interface PopularProductsProps {
    products: Product[];
}

const PopularProducts: React.FC<PopularProductsProps> = ({ products }) => {
    const popularProducts = allProducts.filter((product) => product.isPopular);
    const { addToCart, updateQuantity } = useCart();
    const [quantities, setQuantities] = useState<{ [key: string]: number }>({});
    const [currentIndex, setCurrentIndex] = useState(0); // Suivre l'index de la première position des produits visibles

    const productsPerRow = 3;
    const totalProducts = popularProducts.length;

    // Produits à afficher en fonction de l'index actuel
    const displayedProducts = popularProducts.slice(currentIndex, currentIndex + productsPerRow);

    // Fonction pour faire défiler les produits vers la gauche
    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - productsPerRow);
        }
    };

    // Fonction pour faire défiler les produits vers la droite
    const handleNext = () => {
        if (currentIndex + productsPerRow < totalProducts) {
            setCurrentIndex(currentIndex + productsPerRow);
        }
    };

    const handleAddToCart = (product: Product, quantity: number) => {
        const cartItem = {
            id: product.id.toString(),
            name: product.name,
            quantity,
            price: parseFloat(product.price),
            media: product.media,
            brand: product.brand,
            unitSize: product.unitSize,
            unitPrice: product.unitPrice,
            shopBy: product.shopBy,
        };
        addToCart(cartItem, quantity);
    };

    const handleQuantityChange = (productName: string, quantity: number) => {
        setQuantities((prev) => ({ ...prev, [productName]: quantity }));

        const product = popularProducts.find((p) => p.name === productName);
        if (product && quantity > 0) {
            updateQuantity(product.name, quantity);
        }
    };

    return (
        <div className="popular-products-container  border-top">
            <div className="content-section">
                <div className="text-column">
                    <h2>Popular Products</h2>
                    <Link to="/all-products" className="view-button mobile-only">
                        View All
                    </Link>


                    <div className="navigation-and-description">
                        <p>Discover our selection of popular products. Browse through them and find what you love!</p>
                        {totalProducts > 0 && (
                            <div className="product-navigation">
                                <button className="prev-button" onClick={handlePrev} disabled={currentIndex === 0}>
                                    <FaChevronLeft />
                                </button>
                                <button
                                    className="next-button"
                                    onClick={handleNext}
                                    disabled={currentIndex + productsPerRow >= totalProducts}
                                >
                                    <FaChevronRight />
                                </button>
                            </div>
                        )}
                    </div>

                    <div className="product-list-column">
                        <div className="product-list1">
                            {displayedProducts.map((product, index) => (
                                <div
                                    className={`product-item ${index !== displayedProducts.length - 1 ? 'border-right' : ''}`}
                                    key={product.id}
                                >
                                    <div className="flex justify-center mb-4">
                                        {product.media.toLowerCase().endsWith('.mp4') ? (
                                            <video src={product.media} className="product-image" />
                                        ) : (
                                            <img src={product.media} alt={product.name} className="product-image" />
                                        )}
                                    </div>

                                    <div className="shop-by-labels">
                                        {product.shopBy.map((shopBy, i) => {
                                            const shopByInfo = ShopByData.find((item) => item.label === shopBy);
                                            return (
                                                shopByInfo && (
                                                    <span key={shopBy} style={{ color: shopByInfo.color, fontSize: 14 }}>
                                                        {shopByInfo.label}
                                                        {i !== product.shopBy.length - 1 && <span className="vertical-divider"> | </span>}
                                                    </span>
                                                )
                                            );
                                        })}
                                    </div>

                                    <div>
                                        <p className="product-brand">{product.brand}</p>
                                        <p className="product-name">{product.name}</p>
                                    </div>

                                    <div>
                                        <p style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className="product-unit-size">{product.unitSize}</span>
                                            <div className="vertical-line"></div>
                                            <span className="original-price">{product.unitPrice}</span>
                                        </p>
                                    </div>

                                    <div className="product-card-container">
                                        <p>
                                            <strong className="current-price">{product.price}</strong>
                                        </p>
                                        <div className="right-container">
                                            <QuantitySelector
                                                initialQuantity={quantities[product.id] || 0}
                                                onQuantityChange={(quantity) => handleQuantityChange(product.name, quantity)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {/* Colonne d'image */}
            <div className="image-column">
                <img src="assets/menu/2 1.PNG" alt="Image des produits populaires" className="product-Image" />
            </div>
        </div>
    );
};

export default PopularProducts;
