import { MenuData } from "./MenuData";

export const FreshData: MenuData[] = [
  {
    label: 'Fresh',
    image: '/assets/menu/cat.avif',
    categories: [
      'Bakery',
      'Deli',
      'Flowers',
      'Fruit',
      'Meat',
      'Seafood',
      'Vegetables',
    ],
  },
];
