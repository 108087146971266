import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { menuDataMapping } from '../Components/Dropdown/DropdownData/menuDataMapping';

import './DepartmentsPage2.css';
import { ShopByData } from '../Components/HomePage/MainContent/ShopBy/ShopByData';
import ShopByMenu from '../Components/HomePage/MainContent/ShopBy/ShopByMenu';


interface ShopByItem {
  id: number;
  label: string;
  color: string;
}


const DepartmentsPage2: React.FC = () => {
  const { departmentName, label } = useParams();

  const [items] = useState<ShopByItem[]>(ShopByData);
  const [activeItemId, setActiveItemId] = useState<number | null>(null);
  const [isShopByVisible, setIsShopByVisible] = useState(true);
  // Données pour le menu Shop By (vous pouvez les adapter selon vos besoins)


  const handleClick = (id: number) => {
    setActiveItemId(id);
  };

  const toggleShopByVisibility = () => {
    setIsShopByVisible(!isShopByVisible);
  };


  // Formatage du nom du département
  const formattedDepartmentName = departmentName
    ? departmentName.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' & ')
    : '';

  const departmentData = menuDataMapping[formattedDepartmentName as keyof typeof menuDataMapping];

  if (!departmentData || !Array.isArray(departmentData)) {
    return (
      <div className="error-message">
        Aucun département trouvé pour "{formattedDepartmentName}". Veuillez vérifier le format ou la disponibilité des données.
      </div>
    );
  }

  // Récupérer la catégorie correspondante au label actuel
  const formattedLabel = label
    ? label.replace(/-/g, ' ').replace(/\b\w/g, char => char.toUpperCase())
    : '';

  const categoryData = departmentData.find(
    (section) => section.label.toLowerCase() === formattedLabel.toLowerCase()
  );

  if (!categoryData) {
    return (
      <div className="error-message">
        Aucune catégorie trouvée pour "{formattedLabel}" dans "{formattedDepartmentName}".
      </div>
    );
  }

  // Fonction pour générer l'URL de catégorie
  const generateUrl = (categoryLabel: string) => {
    return `/category/${categoryLabel.toLowerCase().replace(/ /g, '-').replace(/&/g, 'and')}`;
  };

  // Fonction pour diviser les catégories en colonnes
  const generateColumns = (categories: (string | { label: string; subcategories?: string[] })[]) => {
    const maxItemsPerColumn = 4;
    const columns = [];

    for (let i = 0; i < categories.length; i += maxItemsPerColumn) {
      columns.push(categories.slice(i, i + maxItemsPerColumn));
    }

    return columns;
  };

  return (
    <div className="category-page">
      <nav aria-label="breadcrumb" className="breadcrumbs" >
        <nav aria-label="breadcrumb" className="breadcrumbs">
          <div>
            <ol className="bread">
              <li className="breadcrumb-item" style={{ display: 'block' }}>
                <Link to={`/${departmentName}`}>{formattedDepartmentName}</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page" >
                {formattedLabel}
              </li>
              <ShopByMenu
                items={items}
                activeItemId={activeItemId}
                onClick={handleClick}
              />
            </ol>
          </div>
        </nav>



      </nav>

      {/* Ajoutez le composant ShopByMenu ici */}




      <div className="categories-grid">
        {generateColumns(categoryData.categories).map((column, columnIndex) => (
          <div key={columnIndex} className="category-column">
            {column.map((category, index) => (
              <Link
                key={index}
                to={generateUrl(typeof category === 'string' ? category : category.label)}
                className="category-link"
              >
                {typeof category === 'string' ? category : category.label}
              </Link>
            ))}
          </div>
        ))}
      </div>


    </div>
  );
};

export default DepartmentsPage2;
